export default {
  colors: {
    text: "#24292e",
    background: "#fff",
    modes: {
      dark: {
        text: "#e4e4e4",
        background: "#141414"
      }
    }
  }
};
